import React from 'react';

import type { IconProps } from '@atlaskit/icon/types';
import Icon from '@atlaskit/icon';

const UnlockGlyph = () => (
	<svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13 10.5H7C6.72386 10.5 6.5 10.7239 6.5 11V15C6.5 15.2761 6.72386 15.5 7 15.5H13C13.2761 15.5 13.5 15.2761 13.5 15V11C13.5 10.7239 13.2761 10.5 13 10.5ZM7 9C5.89543 9 5 9.89543 5 11V15C5 16.1046 5.89543 17 7 17H13C14.1046 17 15 16.1046 15 15V11C15 9.89543 14.1046 9 13 9H7Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 5.5L8 10H6.5L6.5 5.5C6.5 3.567 8.067 2 10 2C11.933 2 13.5 3.567 13.5 5.5V6L12 6V5.5C12 4.39543 11.1046 3.5 10 3.5C8.89543 3.5 8 4.39543 8 5.5Z"
			fill="currentColor"
		/>
	</svg>
);

export const UnlockIcon = (props: IconProps) => <Icon {...props} glyph={UnlockGlyph} />;
