import React from 'react';

import type { IconProps } from '@atlaskit/icon/types';
import Icon from '@atlaskit/icon';

const LockGlyph = () => (
	<svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13 10.5H7C6.72386 10.5 6.5 10.7239 6.5 11V15C6.5 15.2761 6.72386 15.5 7 15.5H13C13.2761 15.5 13.5 15.2761 13.5 15V11C13.5 10.7239 13.2761 10.5 13 10.5ZM7 9C5.89543 9 5 9.89543 5 11V15C5 16.1046 5.89543 17 7 17H13C14.1046 17 15 16.1046 15 15V11C15 9.89543 14.1046 9 13 9H7Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.99997 6.5L7.99997 10H6.49997L6.49997 6.5C6.49997 4.567 8.06698 3 9.99997 3C11.933 3 13.5 4.567 13.5 6.5V10H12V6.5C12 5.39543 11.1045 4.5 9.99997 4.5C8.8954 4.5 7.99997 5.39543 7.99997 6.5Z"
			fill="currentColor"
		/>
	</svg>
);

export const LockIcon = (props: IconProps) => <Icon {...props} glyph={LockGlyph} />;
