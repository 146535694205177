import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

import { ShareAndRestrictButtonPlaceholder } from './shareAndRestrictButton/ShareAndRestrictButtonPlaceholder';
import { type ShareAndRestrictButtonProps } from './shareAndRestrictButton/ShareAndRestrictButton';

const ShareAndRestrictButtonLoader = LoadableAfterPaint<any>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ShareAndRestrictButton" */ './shareAndRestrictButton/ShareAndRestrictButton'
			)
		).ShareAndRestrictButton,
	loading: ShareAndRestrictButtonPlaceholder,
});

export const ShareAndRestrictButton = (props: Omit<ShareAndRestrictButtonProps, 'flags'>) => (
	<>
		{/* TODO: PageSegmentLoadStart */}
		<ShareAndRestrictButtonLoader {...props} />
	</>
);

export {
	type ContentType,
	type ContentSubType,
} from './shareAndRestrictButton/ShareAndRestrictButton';

export { getClosestAncestorWithViewRestrictions } from './controller/queries/dataToQueriesState';
